.main {
	--stack-block-size: var(--spacing-xs);
	--columns: 2;
	--item-color-transition-duration: 0s;

	padding-block: var(--spacing-md) var(--spacing-sm);

	background-color: var(--color-core-black);

	color: var(--color-core-white);
	font-weight: bold;
	text-transform: uppercase;
}

.item {
	--stack-inline-size: var(--spacing-xs);

	display: flex;
	height: 1.125rem; /* HACK: way to center text with icon until font gets fixed */

	transition: color var(--item-color-transition-duration);

	color: inherit;
	font-size: inherit;
	line-height: inherit;
}

.dimmed.item {
	color: var(--color-core-dark-grey);
}

.item svg {
	width: var(--base-icon-size);
	height: var(--base-icon-size);
	/* HACK: vertically centering svg  */
	margin-block-start: -5px;

	fill: currentColor;
}

.logo {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	order: 2;
}

.logo > a {
	--block-size: 1.125rem;

	display: block;
	block-size: var(--block-size);
	inline-size: calc(var(--future-farm-logo-aspect-ratio) * var(--block-size));
}

.alt-logo > a {
	inline-size: calc(var(--fazenda-futuro-logo-aspect-ratio) * var(--block-size));
}

.smiley {
	width: 6rem;
	height: 6rem;
}

.switcher {
	grid-column: span 2;
	order: 1;
	margin-block-start: var(--spacing-lg);
}

.visual {
	display: flex;
	justify-content: flex-end;
	order: 2;
	margin-block-start: var(--spacing-md);
}

.legal,
.copyright {
	order: 3;
	margin-block-start: var(--spacing-md);
}

.inline-list {
	display: flex;
	justify-content: flex-end;
}

@media (--lg) {
	.main {
		--columns: 4;

		padding-block: var(--spacing-lg) var(--spacing-md);
	}

	.logo {
		grid-column: span 2;
		flex-direction: column;
		align-items: unset;
		order: unset;
	}

	.logo > a {
		--block-size: 1.25rem;
	}

	.switcher {
		display: flex;
		align-items: flex-end;
		order: unset;
		margin-block-start: 0;
	}

	.visual {
		grid-column: span 2;

		text-align: right;
	}

	.smiley {
		width: 7.25rem;
		height: 7.25rem;
	}

	.legal,
	.copyright {
		grid-column: span 2;
	}

	.item {
		height: 1.25rem;
	}

	.item svg {
		margin-block-start: -4px;
	}
}

@media (--hover) {
	.item:hover {
		color: var(--color-core-dark-grey);
	}
}

@media (--motion) {
	.main {
		--item-color-transition-duration: 0.2s;
	}
}
