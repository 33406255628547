/* Hack: removes autofill styles, see details from here: https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/ */
:autofill,
:autofill:where(:focus, :active, :hover) {
	transition: background-color 5000s ease-in-out 0s;

	background-color: inherit !important;
	box-shadow: none;

	color: inherit !important;
}

::placeholder {
	color: var(--color-core-dark-grey);
}

.form-group > * {
	--stack-block-size: var(--spacing-md);
}

.error {
	margin-block-start: var(--spacing-sm);

	color: var(--color-future-red);
}

.checkbox {
	flex-shrink: 0;
	width: var(--base-icon-size);
	height: var(--base-icon-size);
	padding: 0.25rem;

	border: 1px solid var(--color-core-black-transparent);

	appearance: none;

	cursor: pointer;
}

.checkbox:where(:focus, :hover, :checked) {
	border-color: var(--color-core-black);
}

.checkbox:checked::before {
	content: '';

	display: block;

	width: 100%;
	height: 60%;

	transform: rotate(-45deg);

	border-bottom: 2px solid var(--color-core-black);
	border-left: 2px solid var(--color-core-black);
}
