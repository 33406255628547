.form-submit {
	--stack-inline-size: 0.625rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 3.5rem;
	padding-inline: var(--spacing-md);

	background-color: var(--color-future-green);

	color: var(--color-core-black);
}

.form-submit svg {
	width: var(--smaller-icon-size);
	height: var(--smaller-icon-size);

	fill: var(--color-core-black);
}

.arrow {
	transform: rotate(180deg);
}

.form-submit span {
	/* HACK: vertically centers span  */
	margin-top: 0.3rem;
}

.smiley {
	position: absolute;
	margin: 0;
	padding: 0;
}

.smiley svg {
	width: 1.25rem;
	height: 1.25rem;

	vertical-align: initial;
}

.form-submit:disabled,
.invalid {
	color: var(--invalid-input-color, --color-future-green-dark);
}

.invalid svg,
.form-submit:disabled svg {
	fill: var(--invalid-input-color, --color-future-green-dark);
}

.hide {
	visibility: hidden;
}

.remove-label {
	display: none;
}

.grow {
	justify-content: center;
	width: 100%;
}

@media (--lg) {
	.remove-label {
		display: inline;
	}

	.grow {
		width: initial;
	}
}

@media (hover: hover) {
	.form-submit:hover:not(:disabled) .arrow {
		transform: translateX(0.6rem) rotate(180deg);
	}
}

@media (--motion) {
	.form-submit:not(:disabled) .arrow {
		transition: transform 0.2s;
	}

	.smiley svg {
		transform-style: preserve-3d;
		perspective: 1000px;
		animation: loading 3.5s linear infinite;
	}

	@keyframes loading {
		0% {
			transform: rotateY(0);
		}

		100% {
			transform: rotateY(360deg);
		}
	}
}
