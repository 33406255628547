.navigation {
	min-height: 100%;
}

.logo {
	display: none;
}

.link-wrapper {
	display: flex;
	position: relative;
	align-items: center;
	gap: var(--spacing-xs);
}

.link {
	--padding-block-start-correction: 0.2em;

	display: block;
	position: relative;
	/* Align the text in its box better */
	padding-block-start: var(--padding-block-start-correction);

	color: var(--color-core-black);
}

.chevron {
	display: block;
	block-size: 0.5625rem;
	inline-size: 0.875rem;
}

.toggle.is-expanded .chevron {
	transform: rotate(-180deg);
}

.subnavigation {
	display: block;
}

.subnavigation[aria-hidden='true'] {
	display: none;
}

@media (--motion) {
	.chevron {
		transition: transform 150ms ease-in-out;
	}
}

/* Styles below --lg so we don’t need to reset these above --lg */
@media not screen and (--lg) {
	.navigation {
		padding-block: 1.5rem;
		padding-inline: var(--base-container-margins);
	}

	.logo {
		display: block;
	}

	.list {
		margin-block-start: 1.5rem;
	}

	.item {
		border-block-start: 1px solid;
	}

	.item:last-child {
		border-block-end: 1px solid;
	}

	.link {
		/* Make target area bigger and align the text in its box better */
		padding-block: calc(1em + var(--padding-block-start-correction)) 1em;
	}

	.link-wrapper > .link {
		flex: 1;
	}

	.chevron {
		/* Align the chevron with the the close button */
		inline-size: 1.5rem;
		padding-inline: 0.375rem;
	}

	.subnavigation {
		padding-block-end: 1.5rem;
	}
}

@media (--lg) {
	.list {
		gap: 1.5rem;

		display: flex;
	}

	.link-wrapper::after {
		content: '';

		position: absolute;

		inset-block: 0;
		inset-inline: -0.25em;

		z-index: -1;

		background-color: var(--link-background-color, transparent);
	}

	.link-wrapper.is-active {
		--link-background-color: var(--color-future-green);
	}

	.subnavigation {
		position: absolute;
		inset-inline: 0;
		inset-block-start: var(--navigation-block-size);
	}

	.subnavigation-content {
		padding-block: var(--spacing-lg);
		padding-inline: var(--base-container-margins);

		background-color: var(--color-core-white);
		border-block: 1px solid;
	}

	@media (--motion) {
		.subnavigation {
			overflow: hidden;
		}

		.subnavigation[aria-hidden='true'] {
			display: block;

			pointer-events: none;
		}

		.subnavigation-content {
			transition: transform 200ms ease-in-out;
		}

		.subnavigation[aria-hidden='true'] .subnavigation-content {
			transform: translateY(-100%);
		}
	}

	@media (--hover) {
		.link-wrapper:hover {
			--link-background-color: var(--color-future-green);
		}

		@media (--motion) {
			.link-wrapper::after {
				transition: background-color 200ms ease-in-out;
			}
		}
	}
}
