.list {
	--column-count: 1;
	--gap: var(--spacing-sm);

	display: grid;
	grid-template-columns: repeat(var(--column-count), 1fr);
	gap: var(--gap);
}

@media (--md) {
	.list {
		--column-count: 2;
	}
}

@media (--lg) {
	.list {
		--gap: var(--spacing-md);
		--column-count: 3;
	}
}
