.cookie-notice {
	position: fixed;
	z-index: 3;
	bottom: 0;
	width: 100%;
	padding-block: var(--spacing-md);
	padding-inline: var(--base-container-margins);

	background: var(--color-core-white);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.cookie-notice > p {
	--fluid-font-min: 1;
	--fluid-font-max: 1.125;
	line-height: 1.4;
}

.cookie-notice br {
	display: none;
}

.btn-container {
	display: flex;
	gap: var(--spacing-md);

	margin-block-start: var(--spacing-md);
}

.cookie-notice a,
.cookie-notice button:first-of-type {
	color: var(--color-core-dark-grey);
}

@media (--md) {
	.cookie-notice br {
		display: block;
	}
}

@media (--xl) {
	.cookie-notice {
		display: flex;
		justify-content: space-between;
	}

	.btn-container {
		margin-block-start: 0;
	}
}
