:root {
	/* transitions */
	--navigation-transition-duration: 0;

	/* fonts */
	--font-stack-sans: 'Neusa', Arial, sans-serif;
	--font-stack-title: 'Pressio', Impact, Arial Narrow, Arial, sans-serif;

	/* colors */
	--color-core-white: hsl(0, 0%, 100%);
	--color-core-black: hsl(0, 0%, 0%);
	--color-core-dark-grey: hsl(0, 0%, 46%);
	--color-core-light-grey: hsl(240, 8%, 95%);
	--color-future-green: hsl(142, 64%, 47%);
	--color-future-green-dark: hsl(78, 87%, 14%);
	--color-future-purple: hsl(245, 63%, 70%);
	--color-future-red: hsl(357, 100%, 62%);
	--color-future-blue: hsl(210, 71%, 55%);
	--color-future-blue-dark: hsl(210, 70%, 45%);
	--color-future-yellow: hsl(46, 90%, 55%);
	--color-core-black-transparent: hsla(0, 0%, 0%, var(--alpha, 0.5));
	--color-error-red: hsl(357, 100%, 25%);

	/* margins */
	--base-container-margins: 1rem;

	/* sizes */
	--base-icon-size: 1.5rem;
	--smaller-icon-size: 1.125rem;
	--navigation-block-size: 4.5rem;

	/* aspect ratios */
	--future-farm-logo-aspect-ratio: 209 / 24;
	--fazenda-futuro-logo-aspect-ratio: 238 / 25;
}

@media (--motion) {
	:root {
		--navigation-transition-duration: 150ms;
		--product-details-transition-duration: 300ms;
	}
}

@media (--sm) {
	:root {
		--base-container-margins: var(--spacing-md);
	}
}

@media (--lg) {
	:root {
		--base-container-margins: var(--spacing-lg);
	}
}
