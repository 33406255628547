.header {
	/* compensate for the scrollbar gutter if scroll-lock is active */
	--padding-inline-end: calc(
		var(--base-container-margins) + var(--scroll-lock-scrollbar-gutter, 0rem)
	);

	display: flex;

	position: fixed;
	z-index: 3;

	justify-content: space-between;

	padding-block: 1.5rem;
	padding-inline: var(--base-container-margins) var(--padding-inline-end);

	background: var(--color-core-white);
	inset: 0 0 auto;

	gap: var(--spacing-sm);
}

.header > * {
	flex: 0 0 auto;
}

.openButton {
	block-size: var(--base-icon-size);
	inline-size: var(--base-icon-size);
}
