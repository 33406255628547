.openButton {
	block-size: var(--base-icon-size);
	inline-size: var(--base-icon-size);
}

.dialog {
	background: var(--color-core-white);
}

@media (--motion) {
	.container[aria-hidden='true'] .dialog {
		transform: translateY(-100%);
	}
}
