.product {
	position: relative;
}

.figure {
	display: grid;
	grid-template: 1fr / 1fr;
	overflow: hidden;

	background-color: var(--product-link-highlight-color);
	aspect-ratio: 4 / 5;
	mask-image: var(--product-link-mask);
	mask-size: cover;

	place-items: center;
}

@supports not (aspect-ratio: auto) {
	.figure {
		display: block;
		position: relative;
		padding-top: 125%;
	}

	.figure img {
		position: absolute;
		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);
	}
}

.figure > * {
	grid-area: 1 / 1 / 2 / 2;
}

.packshot {
	--product-link-packshot-width: 80%;

	width: calc(
		var(--product-link-packshot-width-multiplier) *
			var(--product-link-packshot-width)
	);
}

.image {
	display: none;
}

.link {
	display: block;
	margin-block-start: var(--spacing-sm);
}

@media (--md) {
	.packshot {
		--product-link-packshot-width: 70%;
	}
}

@media (--hover) {
	.image {
		display: block;

		opacity: 0;
	}

	.product:hover .image {
		opacity: 1;
	}

	@media (--motion) {
		.image {
			transition: opacity 200ms ease-in-out;
		}
	}
}
