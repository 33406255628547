.select {
	display: block;
	width: 100%;
	height: 2.25rem;
	padding-inline-end: 1.3rem;

	border: none;
	border-bottom: 1px solid var(--color-core-dark-grey);
	border-radius: 0;

	background-color: initial;
	background-image: url('/svgs/select-arrow.svg');
	background-repeat: no-repeat;
	background-position: calc(100% - 1px) center;
	background-size: 20px 12px;

	text-overflow: ellipsis;
	appearance: none;
}

.placeholder {
	color: var(--color-core-dark-grey);
}

.hasError {
	border-bottom-color: var(--color-future-red);
}
