.grid {
	display: grid;
	grid-template-columns: repeat(var(--columns), 1fr);
	padding-inline: var(--spacing-sm);
	gap: var(--gap, 0);
}

@media (--sm) {
	.grid {
		padding-inline: var(--spacing-md);
	}
}

@media (--lg) {
	.grid {
		padding-inline: var(--spacing-lg);
	}
}
