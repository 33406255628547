.swiper {
	width: 100%;
}

.swiper-slide {
	display: flex;
	justify-content: center;
}

.swiper-button-prev,
.swiper-button-next {
	width: var(--swiper-navigation-size);

	background-color: var(--color-core-black);
	--swiper-navigation-size: 4rem;
}

.swiper-button-next svg,
.swiper-button-prev svg {
	width: var(--base-icon-size);
	height: var(--base-icon-size);

	transition: transform 0.1s;

	fill: var(--color-core-white);
}

.swiper-button-prev {
	left: 0;
}

.swiper-button-next {
	right: 0;
}

.swiper-button-next svg {
	transform: rotate(180deg);
}

.swiper-button-next::after,
.swiper-button-prev::after {
	display: none;
}

@media (--hover) {
	.swiper-button-next:hover svg {
		transform: translateX(0.6rem) rotate(180deg);
	}

	.swiper-button-prev:hover svg {
		transform: translateX(-0.6rem);
	}
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
	display: flex;
	inset-block-end: var(--spacing-md);

	padding-inline: var(--spacing-sm);
}

.swiper-pagination-bullet {
	position: relative;
	width: 100%;
	height: 0.2rem;
	overflow: hidden;

	border-radius: 0;

	background-color: rgba(255, 255, 255, 0.4);
}

.swiper-pagination-bullet::before {
	content: '';

	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background-color: var(--color-core-white);
}

@keyframes slide-progress {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(0);
	}
}

@media (--md) {
	.swiper-button-prev,
	.swiper-button-next {
		--swiper-navigation-size: 5rem;
	}
}

@media (--lg) {
	.swiper-wrapper {
		transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
	}
}

@media (--motion) {
	.swiper-pagination-bullet-active::before {
		animation: slide-progress 8s linear forwards;
	}

	.swiper-pagination-bullet-active::before .swiper-paused {
		animation-play-state: paused;
	}
}
