.logo {
	--block-size: 1.25rem;

	display: block;
	block-size: var(--block-size);
	inline-size: calc(var(--future-farm-logo-aspect-ratio) * var(--block-size));
}

.alt-logo {
	inline-size: calc(
		var(--fazenda-futuro-logo-aspect-ratio) * var(--block-size)
	);
}
