*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
}

html {
	scroll-padding-top: var(--spacing-lg);
}

body {
	min-height: 100vh;
	padding-block-start: var(--navigation-block-size);
	overflow: hidden scroll;

	background: var(--color-core-white);

	color: var(--color-core-black);

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Mobile WebKit */
@supports (-webkit-touch-callout: none) {
	body {
		/* Makes sure fixed positioned elements don't overflow on scroll bounce on iOS */
		clip-path: inset(0 0 0 0);
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font: inherit;
}

ul,
ol {
	padding: 0;

	list-style: none;
}

button {
	padding: 0;

	border: none;

	background-color: transparent;

	color: inherit;
	font: inherit;

	cursor: pointer;
}

img,
svg {
	max-width: 100%;
	height: auto;

	vertical-align: middle;
}

svg {
	fill: currentColor;
}

[hidden] {
	display: none !important;
}

a {
	color: currentColor;
	text-decoration: none;
}

:not(.keyboard-focus) *:focus {
	outline: none;
}

.keyboard-focus *:focus {
	outline: 2px var(--color-future-blue) solid;
}
