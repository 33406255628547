/* Visually hide element while keeping it accessible. */
/* https://kittygiraudel.com/snippets/sr-only-class/ */
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);

	border: 0;

	white-space: nowrap;
	clip-path: inset(50%);
}
