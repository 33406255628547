.page {
	position: relative;
}

.link {
	display: block;
	margin-block-start: var(--spacing-sm);
}

.subtitle {
	color: var(--color-core-dark-grey);
}
