.form-result {
	--stack-block-size: var(--spacing-md);
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;

	padding-block-start: var(--spacing-lg);
	padding-block-end: 6rem;
	padding-inline: var(--spacing-sm);

	opacity: 0;

	background-color: var(--color-core-white);

	white-space: pre-line;
}

.is-open {
	display: block;

	opacity: 1;
}

@media (--lg) {
	.form-result {
		padding-block: var(--spacing-xl);
		padding-inline: var(--spacing-lg);

		white-space: initial;
	}
}

@media (--motion) {
	.form-result {
		transition: opacity 0.3s;
	}
}
