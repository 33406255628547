:root {
	--spacing-xs: 0.5rem;
	--spacing-sm: 1rem;
	--spacing-md: 2rem;
	--spacing-lg: 4rem;
	--spacing-xl: 8rem;
	--spacing-xxl: 16rem;
}

.stack-block > * + * {
	margin-block-start: var(--stack-block-size, var(--spacing-sm));
}

.stack-inline > * + * {
	margin-inline-start: var(--stack-inline-size, var(--spacing-sm));
}
