.newsletter-signup {
	--stack-block-size: var(--spacing-md);

	position: relative;
	min-height: 23.375rem;

	padding-block-start: var(--spacing-lg);
	padding-block-end: 6rem;
	padding-inline: var(--spacing-sm);

	background-color: var(--background-color);
}

.dark-theme {
	--background-color: var(--color-future-green);
	--invalid-input-color: var(--color-future-green-dark);
}

.light-theme {
	--background-color: var(--color-core-white);
	--invalid-input-color: var(--color-core-dark-grey);
}

.form-result {
	background-color: var(--color-future-green);
}

.title,
.form-group {
	--stack-block-size: var(--spacing-xs);
}

.privacy-checkbox p,
.error {
	color: var(--color-error-red);
}

.email {
	display: flex;
	position: relative;
	align-items: center;

	padding-block-end: var(--spacing-xs);
}

.email.has-errors {
	border-bottom-color: var(--color-error-red);
}

.email input {
	width: 100%;

	height: 2.8rem;

	border: none;

	background-color: initial;

	text-overflow: ellipsis;
}

.email input::placeholder,
.newsletter-signup a {
	color: var(--invalid-input-color);
}

.email button {
	flex: 1 0 2em;
	align-items: initial;
	justify-content: flex-end;
	height: 1.125rem;
	padding-inline: inherit;

	background-color: initial;
}

.email button span {
	margin-top: 0;
}

.input-underline {
	position: absolute;
	bottom: 0;
	width: 100%;

	border-bottom: 1px solid var(--invalid-input-color);
}

input:focus ~ .input-underline {
	border-bottom-color: var(--color-core-black);
}

@media (--md) {
	.email button {
		flex: 1 0 8em;
	}
}

@media (--lg) {
	.newsletter-signup {
		min-height: 31.6975rem;
		padding-block: var(--spacing-xl);
		padding-inline: var(--spacing-lg);
	}
}

@media (--hover) {
	.submit:hover svg {
		transform: translateX(0.6rem) rotate(180deg);
	}

	.privacy-link:hover {
		color: var(--color-core-black);
	}

	input:hover ~ .input-underline {
		border-bottom-color: var(--color-core-black);
	}
}

@media (--motion) {
	.input-underline {
		transition: border-bottom-color 0.2s;
	}

	.newsletter-signup a {
		transition: opacity 0.2s;
	}
}
