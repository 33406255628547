.group {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-md);
}

.wrapper {
	--stack-inline-size: var(--spacing-sm);
	--stack-block-size: var(--spacing-md);
}
