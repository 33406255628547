@font-face {
	font-family: 'Pressio';
	font-weight: normal;
	font-display: swap;

	src: url('/fonts/Pressio-No35.woff2') format('woff2'),
	url('/fonts/Pressio-No35.woff') format('woff');
}

@font-face {
	font-family: 'Neusa';
	font-weight: normal;
	font-display: swap;

	src: url('/fonts/NeusaNextPro-Regular.woff2') format('woff2'),
	 url('/fonts/NeusaNextPro-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Neusa';
	font-weight: 700;
	font-display: swap;

	src: url('/fonts/NeusaNextPro-Bold.woff2') format('woff2'),
	 url('/fonts/NeusaNextPro-Bold.woff') format('woff');
}
