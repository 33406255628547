.list {
	--column-count: 2;
	--gap: var(--spacing-sm);

	gap: var(--gap);

	display: grid;
	grid-template-columns: repeat(var(--column-count), 1fr);
}

@media (--lg) {
	.list {
		--column-count: var(--product-count);
		--gap: calc((32 / 1440) * 100vw);

		display: flex;
		justify-content: var(--justify-content);
	}

	.item {
		flex: 1;
		max-width: min(calc((280 / 1440) * 100vw), calc(280 / 16 * 1rem));
	}
}
