[class*='fluid-font'] {
	--fluid-font-min: 1;
	--fluid-font-max: 1.25;
	--fluid-font-viewport-max: 90;
	--fluid-font-viewport-min: 20;

	--fluid-font-slope: (var(--fluid-font-max) - var(--fluid-font-min)) /
		(var(--fluid-font-viewport-max) - var(--fluid-font-viewport-min));

	--fluid-font-size: clamp(
		var(--fluid-font-min) * 1rem,
		(
				var(--fluid-font-min) - var(--fluid-font-slope) *
					var(--fluid-font-viewport-min)
			) * 1rem + var(--fluid-font-slope) * 100vw,
		var(--fluid-font-max) * 1rem
	);
}

.fluid-font-t1 {
	--fluid-font-min: 5.75;
	--fluid-font-max: 15;

	font: var(--fluid-font-size) / 0.9 var(--font-stack-title);
	letter-spacing: -0.01em;
	text-transform: uppercase;
}

.fluid-font-t2 {
	--fluid-font-min: 4;
	--fluid-font-max: 7.5;

	font: var(--fluid-font-size) / 0.9 var(--font-stack-title);
	letter-spacing: -0.01em;
	text-transform: uppercase;
}

.fluid-font-t3 {
	--fluid-font-min: 2;
	--fluid-font-max: 3.25;

	font: var(--fluid-font-size) / 1.25 var(--font-stack-sans);
	font-weight: 700;
}

.fluid-font-t4,
.fluid-font-t4-bold {
	--fluid-font-min: 1.5;
	--fluid-font-max: 2.25;

	font: var(--fluid-font-size) / 1.25 var(--font-stack-sans);
}

.fluid-font-t5,
.fluid-font-t5-bold {
	--fluid-font-min: 1.0625;
	--fluid-font-max: 1.375;

	font: var(--fluid-font-size) / 1.25 var(--font-stack-sans);
}

.fluid-font-t6,
.fluid-font-t6-bold {
	--fluid-font-min: 1;
	--fluid-font-max: 1.125;

	font: var(--fluid-font-size) / 1.25 var(--font-stack-sans);
	letter-spacing: 0.02em;
}

.fluid-font-t6-bold {
	text-transform: uppercase;
}

.fluid-font-t7 {
	--fluid-font-min: 0.75;
	--fluid-font-max: 0.875;

	font: var(--fluid-font-size) / 1.25 var(--font-stack-sans);
	font-weight: 700;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.fluid-font-t4,
.fluid-font-t5,
.fluid-font-t6 {
	font-weight: 400;
}

.fluid-font-t4-bold,
.fluid-font-t5-bold,
.fluid-font-t6-bold {
	font-weight: 700;
}
