.openButton {
	display: flex;
	align-items: center;

	color: var(--color-core-white);
	text-align: start;
	text-transform: uppercase;
	gap: var(--spacing-xs);
}

.openButton > svg {
	block-size: var(--base-icon-size);
	inline-size: var(--base-icon-size);
}

.openButton > span {
	/* Align text with the svg */
	block-size: 0.9em;
}

.title {
	display: flex;
	align-items: center;
	/* Align first line of text with the close button */
	margin-block-start: calc((var(--base-icon-size) - 0.9em) / 2);
}

.dialog {
	padding-block: 1.5rem var(--spacing-lg);
	padding-inline: var(--base-container-margins);

	background-color: var(--color-core-black);

	color: var(--color-core-white);
}

.nav {
	margin-block-start: var(--spacing-lg);
}

@media (--hover) {
	.openButton:hover {
		color: var(--color-core-dark-grey);
	}

	@media (--motion) {
		.openButton {
			transition: color 200ms;
		}
	}
}
