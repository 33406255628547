.textarea {
	display: block;

	width: 100%;
	min-height: 2rem;
	padding-inline: var(--spacing-sm);

	border: 1px solid var(--color-core-dark-grey);

	background-color: initial;

	line-height: 2rem;

	text-overflow: ellipsis;

	resize: vertical;
}
