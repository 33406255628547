.container,
.overlay {
	position: fixed;
	height: 100%;

	/* Using 100svh provides a better experience when hiding the toolbar */
	/* stylelint-disable-next-line unit-no-unknown */
	height: 100svh;
	inset: 0;
}

.container {
	/* Ensure the container sits on top of the rest of the website */
	z-index: 3;
}

.container:focus {
	/* This is very important in order to prevent performance issues in Safari */
	outline: none;
}

.container[aria-hidden='true'] {
	display: none;

	pointer-events: none;
}

.overlay {
	z-index: 1;

	background: hsla(0, 0%, 0%, 0.5);
}

.dialog {
	position: relative;
	z-index: 3;
	height: inherit;
	overflow: hidden;
}

.container:not([aria-hidden='true']) .dialog {
	overflow: hidden scroll;
}

.title {
	/* Make room for the close button */
	margin-inline-end: calc(var(--base-icon-size) + var(--spacing-sm));
}

.closeButton {
	position: absolute;
	inset-block-start: 1.5rem;
	inset-inline-end: var(--base-container-margins);
}

.closeButton > svg {
	block-size: var(--base-icon-size);
	inline-size: var(--base-icon-size);

	fill: currentColor;
}

@media (--motion) {
	.container[aria-hidden='true'] {
		display: block;
	}

	.overlay {
		transition: opacity 300ms ease-in-out;

		opacity: 1;
	}

	.container[aria-hidden='true'] .overlay {
		opacity: 0;
	}

	.dialog {
		transition: transform 300ms ease-in-out;
	}

	.container[aria-hidden='true'] .dialog {
		transform: translateY(100vh);
	}
}
