.nav {
	--stack-block-size: var(--spacing-md);
}

.link {
	color: inherit;
	text-decoration: none;
	text-transform: capitalize;
}

@media (--hover) {
	.link:hover {
		color: var(--color-core-dark-grey);
	}

	@media (--motion) {
		.link {
			transition: color 200ms;
		}
	}
}
