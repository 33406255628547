.input {
	display: block;
	width: 100%;
	height: 2.25rem;

	border: none;
	border-bottom: 1px solid var(--color-core-dark-grey);

	background-color: transparent;

	text-overflow: ellipsis;
}

.hasError {
	border-bottom-color: var(--color-future-red);
}
